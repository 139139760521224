// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-behandlung-akupunktur-jsx": () => import("./../../../src/pages/behandlung/akupunktur.jsx" /* webpackChunkName: "component---src-pages-behandlung-akupunktur-jsx" */),
  "component---src-pages-behandlung-botox-jsx": () => import("./../../../src/pages/behandlung/botox.jsx" /* webpackChunkName: "component---src-pages-behandlung-botox-jsx" */),
  "component---src-pages-behandlung-eigenblut-aesthetik-jsx": () => import("./../../../src/pages/behandlung/eigenblut-aesthetik.jsx" /* webpackChunkName: "component---src-pages-behandlung-eigenblut-aesthetik-jsx" */),
  "component---src-pages-behandlung-ernaehrungsberatung-jsx": () => import("./../../../src/pages/behandlung/ernaehrungsberatung.jsx" /* webpackChunkName: "component---src-pages-behandlung-ernaehrungsberatung-jsx" */),
  "component---src-pages-behandlung-filler-jsx": () => import("./../../../src/pages/behandlung/filler.jsx" /* webpackChunkName: "component---src-pages-behandlung-filler-jsx" */),
  "component---src-pages-behandlung-haarausfall-jsx": () => import("./../../../src/pages/behandlung/haarausfall.jsx" /* webpackChunkName: "component---src-pages-behandlung-haarausfall-jsx" */),
  "component---src-pages-behandlung-hyperhidrosis-jsx": () => import("./../../../src/pages/behandlung/hyperhidrosis.jsx" /* webpackChunkName: "component---src-pages-behandlung-hyperhidrosis-jsx" */),
  "component---src-pages-behandlung-jsx": () => import("./../../../src/pages/behandlung.jsx" /* webpackChunkName: "component---src-pages-behandlung-jsx" */),
  "component---src-pages-behandlung-klassische-schmerztherapie-jsx": () => import("./../../../src/pages/behandlung/klassische-schmerztherapie.jsx" /* webpackChunkName: "component---src-pages-behandlung-klassische-schmerztherapie-jsx" */),
  "component---src-pages-behandlung-mesotherapie-aesthetik-jsx": () => import("./../../../src/pages/behandlung/mesotherapie-aesthetik.jsx" /* webpackChunkName: "component---src-pages-behandlung-mesotherapie-aesthetik-jsx" */),
  "component---src-pages-behandlung-mesotherapie-schmerzbehandlung-jsx": () => import("./../../../src/pages/behandlung/mesotherapie-schmerzbehandlung.jsx" /* webpackChunkName: "component---src-pages-behandlung-mesotherapie-schmerzbehandlung-jsx" */),
  "component---src-pages-behandlung-mikronaehrstofftherapie-jsx": () => import("./../../../src/pages/behandlung/mikronaehrstofftherapie.jsx" /* webpackChunkName: "component---src-pages-behandlung-mikronaehrstofftherapie-jsx" */),
  "component---src-pages-behandlung-prp-eigenblut-schmerzbehandlung-jsx": () => import("./../../../src/pages/behandlung/prp-eigenblut-schmerzbehandlung.jsx" /* webpackChunkName: "component---src-pages-behandlung-prp-eigenblut-schmerzbehandlung-jsx" */),
  "component---src-pages-datenschutz-jsx": () => import("./../../../src/pages/datenschutz.jsx" /* webpackChunkName: "component---src-pages-datenschutz-jsx" */),
  "component---src-pages-diagnostik-jsx": () => import("./../../../src/pages/diagnostik.jsx" /* webpackChunkName: "component---src-pages-diagnostik-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-leistungen-aesthetik-jsx": () => import("./../../../src/pages/leistungen/aesthetik.jsx" /* webpackChunkName: "component---src-pages-leistungen-aesthetik-jsx" */),
  "component---src-pages-leistungen-ernaehrung-jsx": () => import("./../../../src/pages/leistungen/ernaehrung.jsx" /* webpackChunkName: "component---src-pages-leistungen-ernaehrung-jsx" */),
  "component---src-pages-leistungen-schmerz-jsx": () => import("./../../../src/pages/leistungen/schmerz.jsx" /* webpackChunkName: "component---src-pages-leistungen-schmerz-jsx" */),
  "component---src-pages-praxis-dr-burkhard-schubert-jsx": () => import("./../../../src/pages/praxis/dr-burkhard-schubert.jsx" /* webpackChunkName: "component---src-pages-praxis-dr-burkhard-schubert-jsx" */),
  "component---src-pages-praxis-dr-kathrin-kostka-jsx": () => import("./../../../src/pages/praxis/dr-kathrin-kostka.jsx" /* webpackChunkName: "component---src-pages-praxis-dr-kathrin-kostka-jsx" */),
  "component---src-pages-praxis-index-jsx": () => import("./../../../src/pages/praxis/index.jsx" /* webpackChunkName: "component---src-pages-praxis-index-jsx" */)
}

